<template lang="pug">
#app
  v-container(grid-list-xl, fluid)
    v-layout(row, wrap)
      v-flex(lg12)
        v-card
          v-toolbar(card, color='white')
            v-text-field.hidden-sm-and-down(flat, solo, prepend-icon='search', placeholder='Buscar categoria de feedback', v-model='search', hide-details)
            v-switch.mt-4(:false-value="0", :true-value="1", :color="filter.ativo ? 'success': ''", :label="filter.ativo ? 'Categoria de feedbacks ativas' : 'Categoria de feedbacks inativas'", v-model="filter.ativo")
            v-btn(color="bt-primary", @click="openDialogCategoriaFeedback()").white--text Cadastrar
          v-divider
          v-card-text.pa-0
            v-data-table.elevation-1(:headers='headers', :search='search', :items='list', :rows-per-page-items="[25,50,75,{text:'Todos','value':-1}]", :loading="loading")
              template(slot='items', slot-scope='props')
                td.text-xs-left {{ props.item.texto }}
                td.text-xs-right
                  v-tooltip(top)
                    v-btn(slot="activator", flat, icon, fab, dark, color='bt-primary', small, @click="openDialogCategoriaFeedback(props.item)")
                      v-icon edit
                    span Editar
                  v-tooltip(top)
                    v-btn(slot="activator", flat, icon, fab, dark, :color="props.item.ativo ? 'red' : 'warning'", small, @click="changeAtivo(props.item)")
                      v-icon {{ props.item.ativo ? 'delete' : 'restore' }}
                    span {{ props.item.ativo ? 'Desativar' : 'Ativar' }}
                    
    v-dialog(v-model="dialogCategoriaFeedback", width="auto", scrollable, max-width="500px", persistent, v-if="dialogCategoriaFeedback")
      v-card
        v-card-title
          span.headline {{ categoriaFeedbackAux.id ? 'Editar Categoria' : 'Cadastrar Categoria' }}
          v-spacer
          v-tooltip(top)
            v-btn(icon, slot="activator", @click.native="$validator.pause();  categoriaFeedbackAux = {}; dialogCategoriaFeedback = false;")
              v-icon close
            span Fechar
        v-card-text
          form(@submit.prevent="salvar")
            v-layout(row, wrap)
              v-flex.md12.xs12
                v-text-field(label="Nome", color="gray", required, v-validate="'required'", :error-messages="errors.collect('texto')", data-vv-name="texto", data-vv-as="Texto", type="text", v-model='categoriaFeedbackAux.texto')
            v-layout(row, wrap)
              v-flex.xs12.text-xs-right
                v-btn(type="submit", color="bt-primary", :loading='loading').white--text Salvar

</template>

<script>
import _ from 'lodash';
import AccessLock from '@/components/AccessLock';

export default {
  components: {
    AccessLock
  },
  $_veeValidate: {
    validator: 'new'
  },
  data: () => ({
    filter: { ativo: 1 },
    categorias_feedbacks: [],
    headers: [
      { text: 'Texto', align: 'left', value: 'texto' },
      { text: 'Ações', align: 'right', sortable: false },
    ],
    search: '',
    loading: false,
    dialogCategoriaFeedback: false,
    categoriaFeedbackAux: {},
  }),
  computed: {
    list () {
      return _.filter(this.categorias_feedbacks, { ativo: this.filter.ativo });
    }
  },
  mounted () {
    this.getCategoriasFeedbacks();
  },
  methods: {
    async getCategoriasFeedbacks () {
      const vm = this;
      try {
        vm.loading = true;
        const response = await vm.$axios.get('/categoria-feedback');
        vm.categorias_feedbacks = response.data.rows;
        vm.loading = false;
      } catch (error) {
        let err = error.response && error.response.data ? error.response.data.error : 'Ocorreu um erro ao buscar os dados. Atualize a página!';
        window.getApp.$emit('APP_ALERT', { color: 'red', text: err });
        vm.loading = false;
      }
    },
    async changeAtivo(categoria_feedback) {
      const vm = this;
      try {
        const obj = _.cloneDeep(categoria_feedback);
        obj.ativo = obj.ativo ? 0 : 1;
        const response = await vm.$axios.put(`/categoria-feedback/${obj.id}`, obj);
        categoria_feedback.ativo = response.data.ativo;
        window.getApp.$emit('APP_ALERT', { color: obj.ativo ? 'success' : 'orange', text: `Categoria de feedback ${categoria_feedback.ativo ? 'ativada' : 'desativada'} com sucesso!` });
      } catch (error) {
        let err = error.response && error.response.data ? error.response.data.error : 'Ocorreu um erro ao salvar a categoria de feedback. Tente novamente!';
        window.getApp.$emit('APP_ALERT', { color: 'red', text: err });
      }
    },
    async save (categoria_feedback) {
      const vm = this;
      try {
        const response = await vm.$axios.post('/categoria-feedback', categoria_feedback);
        categoria_feedback.id = response.data.id;
        vm.categorias_feedbacks.push(response.data);
        vm.loading = false;
        vm.dialogCategoriaFeedback = false;
        vm.categoriaFeedbackAux = {};
        window.getApp.$emit('APP_ALERT', { color: 'success', text: 'Categoria de feedback salva com sucesso!' });
      } catch (error) {
        let err = error.response && error.response.data ? error.response.data.error : 'Ocorreu um erro ao salvar a categoria de feedback. Tente novamente!';
        window.getApp.$emit('APP_ALERT', { color: 'red', text: err });
        vm.loading = false;
      }
    },
    async update (categoria_feedback) {
      const vm = this;
      try {
        const response = await vm.$axios.put(`/categoria-feedback/${categoria_feedback.id}`, categoria_feedback);
        const index = _.findIndex(vm.categorias_feedbacks, { id: categoria_feedback.id });
        const categorias_feedbacks = _.reject(vm.categorias_feedbacks, { id: categoria_feedback.id });
        vm.categorias_feedbacks = [...categorias_feedbacks.slice(0, index), response.data, ...categorias_feedbacks.slice(index)];
        vm.loading = false;
        vm.dialogCategoriaFeedback = false;
        vm.categoriaFeedbackAux = {};
        window.getApp.$emit('APP_ALERT', { color: 'success', text: 'Categoria de feedback salvo com sucesso!' });
      } catch (error) {
        let err = error.response && error.response.data ? error.response.data.error : 'Ocorreu um erro ao atualizar a categoria de feedback. Tente novamente!';
        window.getApp.$emit('APP_ALERT', { color: 'red', text: err });
        vm.loading = false;
      }
    },
    async salvar (scope) {
      const vm = this;
      try {
        vm.loading = true;  
        const result = await vm.$validator.validateAll();
        if (!result) throw 'Preencha todos os campos corretamente!';
        vm[vm.categoriaFeedbackAux.id ? 'update' : 'save'](vm.categoriaFeedbackAux);
        vm.$validator.reset();
      } catch (error) {
        window.getApp.$emit('APP_ALERT', { color: 'red', text: error });
        vm.loading = false;
      }
    },
    async openDialogCategoriaFeedback(categoria) {
      const vm = this;
      try {
        if (categoria && categoria.id) {
          vm.categoriaFeedbackAux = _.cloneDeep(categoria);
          const response = await vm.$axios.get(`/categoria-feedback/${vm.categoriaFeedbackAux.id}`);
          vm.categoriaFeedbackAux = response.data || {};
        }
        vm.dialogCategoriaFeedback = true;
      } catch (error) {
        let err = error.response && error.response.data ? error.response.data.error : 'Ocorreu um erro ao buscar os dados. Atualize a página!';
        window.getApp.$emit('APP_ALERT', { color: 'red', text: err });
      }
    },
  }
};
</script>

<style scoped>

</style>

