<template lang="pug">
  v-container(grid-list-xl, fluid)
    v-layout(row, wrap).mt-5
      v-flex.md12.text-xs-center
        v-icon(size="80").black--text lock
        h2.mt-3 Você não tem permissão pra acessar essa página!
        v-btn(color="primary", :to="{ name: 'Dashboard' }").white--text.mt-4 Ir para o início
</template>

<script>
export default {
};
</script>

<style lang="scss" scoped>
</style>